<template>
  <NavQuestion
    :heading="heading"
    :number="number"
    :subHeading="subHeading"
    :questionOptions="true"
  >
    <template v-slot:contents>
      <Person
        v-for="(person, id) in secondaryPeople"
        v-bind:key="id"
        v-bind:person="person"
        @selected="save"
        @edit="edit"
        :type="'executor_secondary'"
      >
      </Person>
      <PersonAddQuad @addPerson="setNewPerson" :brotherSister="true">
      </PersonAddQuad>
      <PersonModal
        v-show="show.personModal"
        :person="selectedPerson"
        :show="show.personModal"
        :initialPerson="initialPerson"
        @close="closePersonModal"
      ></PersonModal>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="backTo"
        v-bind:center="false"
        v-bind:loading="loading"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import Person from '@/common/ui/Person'
import PersonModal from '@/views/pages/PeoplePage/PersonModal'
import PersonAddQuad from '@/common/ui/PersonAddQuad'

import { willStringHelpers } from '@/common/mixins/willStringHelpers'

export default {
  name: 'ExecutorsSecondary',
  mixins: [willStringHelpers],
  components: {
    NavQuestion,
    NavQuestionButtons,
    Person,
    PersonModal,
    PersonAddQuad
  },
  computed: {
    heading() {
      return 'Who would you like to name as your secondary executor(s)?'
    },
    number() {
      if (this.partner && this.partner.executor_sole_primary) return '3'
      return '4'
    },
    mainExecutors() {
      return this.$store.getters.people.filter(
        (person) => person.executor_primary
      )
    },
    mainExecutorsText() {
      if (this.partner && this.partner.executor_sole_primary)
        return this.partner.full_name + ' is'
      if (this.mainExecutors.length === 1)
        return this.listPeople(this.mainExecutors) + ' is'
      if (this.mainExecutors.length > 1)
        return this.listPeople(this.mainExecutors, ' and ', true) + ' are'
      return ''
    },
    subHeading() {
      var string =
        'You should plan for every eventuality and name people who can step in if ' +
        this.mainExecutorsText +
        ' unable or un-willing to act.  '
      if (this.youngChildren.length)
        string +=
          'Executors must be over the age of 18, when ' +
          this.listPeople(this.youngChildren, ' and ', true) +
          ' are older you may consider updating your Will and having them added as Executors.'
      return string
    },
    secondaryPeople() {
      return this.$store.getters.people.filter(
        (person) =>
          !person.executor_primary &&
          !person.under18 &&
          !person.executor_backup &&
          !person.executor_sole_primary
      )
    },
    people() {
      return this.$store.getters.people
    },
    youngChildren() {
      return this.people.filter((person) => person.child && person.under18)
    },
    partner() {
      return this.$store.getters.partner
    },

    forwardTo() {
      if (this.partner && this.partner.executor_sole_primary)
        return '/executors/backup_option'
      return '/executors/check_and_confirm'
    },
    backTo() {
      if (this.partner && this.partner.executor_sole_primary)
        return '/executors/partner'
      else if (!this.partner) return '/executors/secondary_option'
      return '/executors/primary'
    },
    isValidated() {
      if (this.people.filter((person) => person.executor_secondary).length)
        return true
      return false
    }
  },
  data() {
    return {
      loading: false,
      selectedPerson: null,
      initialPerson: null,
      show: {
        personModal: false
      }
    }
  },
  methods: {
    save(person) {
      var copyPerson = Object.assign({}, person)
      copyPerson.executor_secondary = !person.executor_secondary
      this.$store.commit('personAdd', copyPerson)
    },
    edit(person) {
      this.selectedPerson = person
      this.show.personModal = true
    },
    closePersonModal() {
      this.selectedPerson = null
      this.show.personModal = false
      this.initialPerson = null
    },
    setNewPerson(value) {
      value.push('executor_secondary')
      this.initialPerson = value
      this.show.personModal = true
      this.selectedPerson = null
    }
  }
}
</script>
